<template>
	<div>

		<form enctype="multipart/form-data">

			<!-- this part is hidden but must be there for the upload field to works -->
			<div class="row justify-content-center" style="height:0;">
				<div>
					<label for="imageUpload" class="form-label" style="height:0; overflow: hidden; position: absolute; margin-left: 10000px; margin-top: 10000px;">This field should not be visible</label>
					<input ref="fileInput" class="form-control" style="height:0; border-bottom: 0px;" id="imageUpload" name="imageUpload" @change="handleFilesUpload($event)" @focus="isFocused = true" @blur="isFocused = false" type="file" multiple>
				</div>
			</div>
			<!-- // -->

			<div class="container">
				<div 
				@drop="launchFileTransfert($event)" 
				@dragover.prevent class="dropzone" 
				:class="{isLoading: isLoading, focus: isFocused}"
				@click="$refs.fileInput.click()" 
				style="text-align: center;" >

				<p class="p1" v-if="uploadFiles.length === 0">{{label}}<span v-if="isRequired" class="req">*</span>
				</p>
				<hr v-if="uploadFiles.length === 0">
				<p class="p2" v-if="uploadFiles.length === 0">
					{{$t('inputLabel.upload.rules')}}
				</p>

				<div v-for="(fileObject, index) in filesStatus" :key="index">

					<div id="frame" class="img-square-50px">
						<div id="coverPdf" class="" @click.stop="displayPdf(fileObject)"></div>
						<img :src="fileObject.preloadImgSrc" class="img-square-50px">
					</div>

					<button @click.stop="removeImg(fileObject)" class="btn_small"><i class="fas fa-times"></i> {{$t('inputLabel.upload.remove')}}</button>

					<p>{{$t('inputLabel.upload.state')}}:
						<span v-if="fileObject.uploadStatus === 'waiting'">{{$t('inputLabel.upload.waiting')}} </span>
						<span v-else-if="fileObject.uploadStatus === 'success'">{{$t('inputLabel.upload.uploaded')}} <i class="fas fa-check"></i></span>
						<span v-else-if="fileObject.uploadStatus === 'error'">{{$t('inputLabel.upload.error')}} <i class="fas fa-times"></i></span>
					</p>

					<div id="successMessages" v-if="messages.length > 0">
						<ul>
							<li class="txt-green" v-for="(msg, index) in messages" :key="index">{{ msg }}</li>
						</ul>
					</div>

					<div id="errorMessages" v-if="fileObject.errorMessage && fileObject.errorMessage.length > 0">
						<ul>
							<li class="txt-red" v-for="(err, index) in fileObject.errorMessage" :key="index">{{ err }}</li>
						</ul>
					</div>

				</div>

			</div>
		</div>

		<div class="restrictionupload">{{$t('inputLabel.upload.onlyImages')}}<br>{{$t('inputLabel.upload.maxSize')}}</div>

	</form>



</div>
</template>

<script>

	import config from "../config";
	import CustomLog from "../mixins/CustomLog";
	import APICalls from "../mixins/APICalls";

	export default {
		name: "Upload",
		mixins: [
			APICalls,
			CustomLog,
			],
		props: {
			label: {
				type: String,
				required: true,
			},
			fieldname: {
				type: String,
				required: true,
			},
			postUploadUrlProp: {
				type: String,
				required: true,
			},
			isRequired : {
				type: Boolean,
				required: false,
			default: false,
			},
			idParticipationOwner : {
				// type: String,
				required: false,
			},
			typeOfUpload : {
				type: String,
				required: true,
				validator : function(value){
					return ["dropzone1","dropzone2","ticket","logo","other"].includes(value);
				}
			},
		},

		data() {
			return {
				uploadFiles: [],
				filesStatus: [],
				messages: [],
				config: config,
				isLoading: false,
				isFocused: false,
			}
		},

		methods: {

			handleFilesUpload: function(event, drop = false) {
				this.resetMessages();
				if(!drop) {
					this.uploadFiles = Array.from(event.target.files)
				}else{
					this.uploadFiles = Array.from(event)
				}
				this.filesStatus = []
				this.uploadFiles.forEach(file => {
					this.filesStatus.push({
						file: file,
						preloadImgSrc: URL.createObjectURL(file),
						uploadSuccess: false,
						uploadStatus: 'waiting'
					})
				})
				this.sendUploadedFiles();
			},

			resetMessages: function() {
				this.messages = [];
			},



			sendPostRequest(){
				/* CONFIG */
				let config = {
					headers: {
						'Accept' : 'application/json',
						'Content-Type': 'multipart/form-data'
					}
				}
				/* FORM */
				let form = new FormData();
				this.resetMessages();
				this.uploadFiles.forEach((file) => {
					form.append('files[]', file)
				});
				form.append('type', this.typeOfUpload);
				form.append('idLeadManagement', this.config.idlead);
				form.append('fingerprint', this.$store.getters.getFingerprint);
				if(this.idParticipationOwner){ form.append('idParticipationOwner', this.idParticipationOwner); }
				/* REQUEST */
				this.axios.post(this.postUploadUrlProp, form, config)
				.then(({data}) => {
					this.postRequestDidWell(data);
				})
				.catch(err => {
					this.postRequestDidWrong(err);
				});
			},

			postRequestDidWell(data){
				data.forEach(serverFileStatus => {
					return this.filesStatus
					.find(localStatus => serverFileStatus.name === localStatus.file.name)
					.uploadStatus = 'success';
				});
				this.messages.push(this.$t('inputLabel.upload.uploadsDone'));
				this.sendCheckSignal(true);
				this.displayOrHidePDF(this.filesStatus[0].file.name);
			},

			postRequestDidWrong(err){
				this.filesStatus.forEach((obj, index) => {
					obj.uploadStatus = 'error'
					obj.errorMessage = ''
					if(err.response.status === 503){
						this.setNotLoading();
						this.$emit('maintenanceDetected');
					}else if(err.response.status === 422) {
						obj.errorMessage = err.response.data.errors[`files.${index}`];
					}else{
						obj.errorMessage = [this.$t('inputLabel.upload.generalError')]
					}
				});
				this.sendCheckSignal(false);
			},



			sendUploadedFiles: function() {
				this.log("sendUploadedFiles", 'function');
				/* Must send a fingerprint with the file */
				/* Problem: some adblocker blocks the fingerprint from the JS and our store is empty */
				/* So, if it's the case, I must get it from ActionAPI */
				let storedFingerPrintShouldBeNonEmpty = this.$store.getters.getFingerprint;
				if(storedFingerPrintShouldBeNonEmpty){
					this.log("Fingerprint was found in store", 'info');
					this.sendPostRequest();
					this.isLoading = false;
				}else{
					this.log("Fingerprint was NOT found in store", 'info');
					this.askActionApiForFingerprint();
				}				
			},

			askActionApiForFingerprint(){
				this.log("Fetching fingerprint from Action API", 'low');
				this.getFromAxios(this.callConfigs.getFingerprint);				
			},

			whatIfIGotTheFingerPrint(fingerprint){
				this.log("Storing the fingerprint", 'low');
				this.$store.commit("SET_FINGERPRINT", fingerprint);
				this.sendPostRequest();
				this.isLoading = false;
			},
			whatIfIDontGetTheFingerprint(){
				this.log("Fingerprint not found", 'alert');
				this.isLoading = false;
			},

			displayOrHidePDF(filename) {
				/* If it's a PDF, shows a PDF */
				let el = document.getElementById('coverPdf');
				if(this.identifyAsPdf(filename)){
					el.classList.add('visible');
				}else{
					el.classList.remove('visible');
				}
			}, 

			identifyAsPdf(filename) {
				return (filename.slice(-3) === "pdf");
			},

			displayPdf(file){
				window.open(file.preloadImgSrc, '_blank').focus();
			},

			removeImg(fileObject) {
				this.filesStatus = this.filesStatus.filter(obj => obj.file.name !== fileObject.file.name)
				this.uploadFiles = this.uploadFiles.filter(file => file.name !== fileObject.file.name)

				if(this.uploadFiles.length > 0) {
					this.sendUploadedFiles();
				}
				this.sendCheckSignal(false);
			},

			launchFileTransfert(event) {
				event.preventDefault();
				event.stopPropagation();
				this.isLoading = true;
				let dt = event.dataTransfer
				let files = dt.files
				this.handleFilesUpload(files, true)
			},

			sendCheckSignal(bool){
				this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
			},


		},

	}
</script>

<style scoped>
	.txt-green {
		color: green;
	}

	.restrictionupload {
		text-align: center;
		font-size: 0.8em;
	}

	.txt-red {
		color: red;
	}

	.img-square-50px {
		height: 180px;
		width: 180px;
		margin: 0 auto 10px;
	}

	.btn_small {
		width: 180px;
	}

	label{
		@media screen and (max-width: 714px){
			text-align: center;
		}
	}

	.dropzone {
		min-height: 150px;
		padding: 20px;
		cursor: pointer;
		font-size: 0.8em;
		text-transform:  uppercase;
		letter-spacing:  0.075em;
	}
	
	#frame {
		position: relative;
		text-align: center;
	}

	#coverPdf {
		position: absolute;
		background: #fc03 url('../assets/img/pdf.png') no-repeat center center / contain;
		width: 100%;
		height: 100%;
		display:  none;
	}
	#coverPdf.visible {
		display:  block;
	}
</style>
